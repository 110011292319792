/* eslint-disable no-restricted-syntax */
import React from 'react';
import refiner from 'refiner-js';

import { useHistory, useLocation, useParams } from 'react-router-dom';

import { EGateway } from '@domain/enums/common/EGateway';
import { EProductCosts } from '@domain/enums/common/EProductCosts';
import { EMambaTermsStatus } from '@domain/enums/common/mamba/EMambaTermStatus';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { EStatus } from '@domain/enums/subscription/ESubscription';
import { EBusinessType, EStoreType } from '@domain/enums/stores/Etype';
import { IParams } from '@domain/interfaces/IParams';
import { IStoreConfigProvider } from '@domain/interfaces/hooks/IStoreConfig';
import { IResyncData, ISynchronization } from '@domain/interfaces/hooks/IStoreSynchronization';
import { IIntegration, IStoreIntegrations } from '@domain/interfaces/stores/IStores';

import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useConfig } from '@helpers/hooks/useConfig';
import { useDate } from '@helpers/hooks/useDate';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { useQuery } from '@helpers/hooks/useQuery';
import dsersAuthService from '@services/pages/dashboard/integrationCenter/erp/dsers/dsersAuth';

import { ERPS, GATEWAYS, STORE_PROVIDERS } from '@constants/common/integrations';

import { getEnvironment } from '@helpers/utils/environment';
import personalInfoService from '@services/pages/auth/personalInfo';
import userGroupService from '@services/pages/dashboard/accessManagement/userGroup';
import feedService from '@services/pages/dashboard/feed/feed';
import mobileNotificationService from '@services/pages/dashboard/profile/mobileNotification';
import storesService from '@services/pages/stores/stores';
import userService from '@services/user';
import checkoutFeeService from '@services/pages/dashboard/checkoutFee/checkoutFee';

import { EBequestTermsStatus } from '@domain/enums/common/bequest/EBequestTermStatus';
import { useLocalStorage } from './useLocalStorage';

const StoreConfigContext = React.createContext<IStoreConfigProvider | null>(null);

export const StoreConfigProvider: React.FC = ({ children }) => {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const { storeAliasId } = useParams<IParams>();
  const { handleTimezone, subDays, utcToZonedTime, format, differenceInDays } = useDate();
  const {
    currentSynchronizations,
    resyncData,
    addSynchronization,
    handleResyncData,
  } = useSynchronization();
  const {
    handleSelectedStore,
    handlePersonalInfo,
    personalInfo: personalInfoConfig,
    analytics,
    user,
    handleStores,
    hasStartedOpenReplay,
    openReplayTracker,
    handleUser,
    handleAppAffiliate,
    handleTerm,
    isInternalTeam,
  } = useConfig();

  const {
    subscription,
    isSubscriptionProviderDataError,
    isLoadingSubscriptionProviderData,
    isValidatingSubscriptionProviderData,
    mutateSubscriptionProviderData,
    handleCurrentStore,
    handleValidatingCurrentStore,
  } = useStoreSubscription();

  const {
    store,
    integrations: storeIntegrations,
    isLoading: isLoadingStore,
    isValidating: isValidatingStore,
    mutate: mutateStore,
    error: storeError,
  } = storesService.getStore({ storeAliasId });

  const {
    stores,
    isLoading: isLoadingStores,
    isValidating: isValidatingStores,
    mutate: mutateStores,
    error: storesError,
  } = storesService.getStores({ page: 0, filter: {} });

  const {
    feedElementsQuantity,
    isLoading: isLoadingFeedQuantity,
    mutate: mutateFeedQuantity,
    error: feedQuantityError,
  } = feedService.getFeedQuantity({
    storeAliasId,
    startDate: format(subDays(utcToZonedTime(new Date()), 29), 'yyyy-MM-dd'),
    endDate: format(utcToZonedTime(new Date()), 'yyyy-MM-dd'),
  });

  const {
    gatewaysToFeed,
    isLoading: isLoadingFeedGateways,
    mutate: mutateFeedGateways,
    error: feedGatewaysError,
  } = feedService.getFeedGateways({
    storeAliasId,
    startDate: format(subDays(utcToZonedTime(new Date()), 29), 'yyyy-MM-dd'),
    endDate: format(utcToZonedTime(new Date()), 'yyyy-MM-dd'),
  });

  const {
    personalInfo,
    isLoading: isLoadingPersonalInfo,
    error: personalInfoError,
  } = personalInfoService.getPersonalInfo();

  const {
    user: userData,
    affiliate: userAffiliate,
    term: userTerm,
    user_intercom_hash: userIntercomHash,
    isLoading: isLoadingUser,
    isValidating: isValidatingUser,
    mutate: mutateUser,
    error: userError,
  } = userService.getUser();

  const {
    role,
    group,
    isLoading: isLoadingUserGroup,
    isValidating: isValidatingUserGroup,
    mutate: mutateUserGroup,
    error: userGroupError,
  } = userGroupService.getUserGroup({ storeAliasId });

  const {
    isLoading: isLoadingMobileNotification,
    mobileNotification,
  } = mobileNotificationService.getMobileNotifications({
    storeAliasId,
  });

  const {
    checkoutFee: defaultCheckoutFee,
    error: defaultCheckoutFeeError,
    isValidating: isValidatingDefaultCheckoutFee,
    isLoading: isLoadingDefaultCheckoutFee,
  } = checkoutFeeService.getDefaultCheckoutFee({ storeAliasId });

  const processingData = query.get('processing_data');
  const noRetroActiveData = query.get('no_retroactive_data');

  const accessManagementData = {
    role,
    group,
  };

  const [isDeleteSyncsModalOpen, setIsDeleteSyncsModalOpen] = React.useState<boolean>(false);
  const [isDomainModalOpen, setIsDomainModalOpen] = React.useState<boolean>(false);
  const [feedQuantity, setFeedQuantity] = React.useState<number>(0);
  const [feedGateways, setFeedGateways] = React.useState<Array<EGateway | 'MANUAL' | 'OTHER'>>([]);
  const [isLoadingInitialCheckoutFee, setIsLoadingInitialCheckoutFee] = React.useState(true);
  const [isLoadingInitialStore, setIsLoadingInitialStore] = React.useState(true);
  const [gatewaySynchronization, setGatewaySynchronization] = React.useState<
    ISynchronization | undefined
  >(undefined);
  const [countMarkedAsRead, setCountMarkedAsRead] = useLocalStorage(
    `@profitfy:${getEnvironment()}/count-feed-ignored`,
    null,
  );
  const [showDsersInactiveAlert, setShowDsersInactiveAlert] = React.useState<boolean>(false);

  const handleMarkAsReadFeed = React.useCallback(() => {
    setCountMarkedAsRead(feedQuantity);
  }, [feedQuantity, setCountMarkedAsRead]);

  const handleDeleteSyncsModalOpen = React.useCallback(
    () => setIsDeleteSyncsModalOpen(state => !state),
    [],
  );

  const getDsersCredential = React.useCallback(async () => {
    const { data } = await dsersAuthService.getDsersCredentialPromise({ storeAliasId });

    const { dsers_credential } = data;

    if (!dsers_credential) return;

    if (dsers_credential.is_operational) return;

    setShowDsersInactiveAlert(true);
  }, [storeAliasId]);

  React.useEffect(() => {
    if (storeIntegrations) {
      const foundDsers = storeIntegrations.product_cost.find(
        integration => integration.name === 'DSERS',
      );

      if (foundDsers?.is_active) {
        getDsersCredential();
      }
    }
  }, [storeAliasId, storeIntegrations, getDsersCredential]);

  const toggleDsersInactiveModal = React.useCallback(() => {
    setShowDsersInactiveAlert(prev => !prev);
  }, []);

  const mutateStoreConfig = React.useCallback(async () => {
    const promises = [];

    if (isSubscriptionProviderDataError) promises.push(mutateSubscriptionProviderData());

    if (storeError) promises.push(mutateStore());

    if (feedQuantityError) promises.push(mutateFeedQuantity());

    if (feedGatewaysError) promises.push(mutateFeedGateways());

    if (storesError) promises.push(mutateStores());

    if (userError) promises.push(mutateUser());

    if (userGroupError) promises.push(mutateUserGroup());

    await Promise.all(promises);
  }, [
    mutateSubscriptionProviderData,
    isSubscriptionProviderDataError,
    storeError,
    mutateStore,
    feedQuantityError,
    mutateFeedQuantity,
    feedGatewaysError,
    mutateFeedGateways,
    storesError,
    mutateStores,
    userError,
    mutateUser,
    userGroupError,
    mutateUserGroup,
  ]);

  const handleDomainModalOpen = React.useCallback(() => {
    setIsDomainModalOpen(!isDomainModalOpen);
  }, [isDomainModalOpen]);

  const addStoreProductsSynchronization = React.useCallback(
    async (foundStoreProviderIntegration: typeof STORE_PROVIDERS[number], data: IResyncData) => {
      const params = `?start_date=${format(new Date(2000, 1, 1), 'yyyy-MM-dd')}&end_date=${format(
        utcToZonedTime(new Date()),
        'yyyy-MM-dd',
      )}`;

      const { generateSyncRequest } = foundStoreProviderIntegration;

      const emptyRequestObject = {};

      addSynchronization({
        name: foundStoreProviderIntegration.name,
        type: ESynchronizationType.STORE_PRODUCT,
        label: 'Produtos',
        dependencies: [],
        storeAliasId: data.storeAliasId,
        config: {
          isOnboardingSynchronization: false,
          showNotification: true,
        },
        request:
          generateSyncRequest?.(ESynchronizationType.STORE_ORDER, data.storeAliasId, params) ||
          emptyRequestObject,
      });

      await new Promise(resolve => setTimeout(resolve, 500));
    },
    [addSynchronization, format, utcToZonedTime],
  );

  const addDsersProductsSynchronization = React.useCallback(
    async (data: IResyncData) => {
      const foundCogsProviderIntegration = ERPS.find(
        integration => integration.identifier === EProductCosts.DSERS,
      ) as typeof ERPS[number];

      const params = `?start_date=${format(new Date(2000, 1, 1), 'yyyy-MM-dd')}&end_date=${format(
        utcToZonedTime(new Date()),
        'yyyy-MM-dd',
      )}`;

      const { generateSyncRequest } = foundCogsProviderIntegration;

      addSynchronization({
        name: foundCogsProviderIntegration.name,
        type: ESynchronizationType.STORE_PRODUCT,
        label: 'Produtos',
        dependencies: [ESynchronizationType.STORE_PRODUCT],
        storeAliasId: data.storeAliasId,
        config: {
          isOnboardingSynchronization: false,
          showNotification: true,
        },
        request: generateSyncRequest(ESynchronizationType.STORE_PRODUCT, data.storeAliasId, params),
      });

      await new Promise(resolve => setTimeout(resolve, 500));
    },
    [addSynchronization, format, utcToZonedTime],
  );

  const addDsersOrdersSynchronization = React.useCallback(
    async (data: IResyncData) => {
      const foundCogsProviderIntegration = ERPS.find(
        integration => integration.identifier === EProductCosts.DSERS,
      ) as typeof ERPS[number];

      const params = `?start_date=${format(
        utcToZonedTime(subDays(new Date(), data.daysToSync)),
        'yyyy-MM-dd',
      )}&end_date=${format(utcToZonedTime(new Date()), 'yyyy-MM-dd')}`;

      const { generateSyncRequest } = foundCogsProviderIntegration;

      addSynchronization({
        name: foundCogsProviderIntegration.name,
        type: ESynchronizationType.STORE_ORDER,
        label: 'Pedidos',
        dependencies: [ESynchronizationType.STORE_PRODUCT, ESynchronizationType.STORE_ORDER],
        storeAliasId: data.storeAliasId,
        config: {
          isOnboardingSynchronization: false,
          showNotification: true,
        },
        request: generateSyncRequest(ESynchronizationType.STORE_ORDER, data.storeAliasId, params),
      });

      await new Promise(resolve => setTimeout(resolve, 500));
    },
    [addSynchronization, format, utcToZonedTime, subDays],
  );

  const addStoreProviderSynchronization = React.useCallback(
    async (foundStoreProviderIntegration: typeof STORE_PROVIDERS[number], data: IResyncData) => {
      const params = `?start_date=${format(
        utcToZonedTime(subDays(new Date(), data.daysToSync)),
        'yyyy-MM-dd',
      )}&end_date=${format(utcToZonedTime(new Date()), 'yyyy-MM-dd')}`;

      const { generateSyncRequest } = foundStoreProviderIntegration;

      const emptyRequestObject = {};

      addSynchronization({
        name: foundStoreProviderIntegration.name,
        type: ESynchronizationType.STORE_ORDER,
        label: 'Pedidos',
        dependencies: [ESynchronizationType.STORE_PRODUCT],
        storeAliasId: data.storeAliasId,
        config: {
          isOnboardingSynchronization: false,
          showNotification: true,
        },
        request:
          generateSyncRequest?.(ESynchronizationType.STORE_ORDER, data.storeAliasId, params) ||
          emptyRequestObject,
      });

      await new Promise(resolve => setTimeout(resolve, 500));
    },
    [addSynchronization, format, utcToZonedTime, subDays],
  );

  const addStoreGatewaysSynchronizations = React.useCallback(
    (
      foundStoreGatewayIntegration: typeof GATEWAYS[number],
      foundStoreProviderIntegration: typeof STORE_PROVIDERS[number],
      data: IResyncData,
    ) => {
      const gatewayIdentifier = foundStoreGatewayIntegration.identifier;

      const hasSynchronization = foundStoreProviderIntegration.gateways.includes(gatewayIdentifier);

      if (
        hasSynchronization ||
        foundStoreGatewayIntegration.isPredefinedOtherGateway ||
        foundStoreGatewayIntegration.identifier === EGateway.OTHER_GATEWAY
      ) {
        const params = `?start_date=${format(
          utcToZonedTime(subDays(new Date(), data.daysToSync)),
          'yyyy-MM-dd',
        )}&end_date=${format(utcToZonedTime(new Date()), 'yyyy-MM-dd')}`;

        const { generateGatewaySyncRequest } = foundStoreProviderIntegration;

        addSynchronization({
          name: foundStoreProviderIntegration.name,
          type: ESynchronizationType.STORE_GATEWAY,
          label: foundStoreGatewayIntegration.name,
          dependencies: [ESynchronizationType.STORE_PRODUCT, ESynchronizationType.STORE_ORDER],
          storeAliasId: data.storeAliasId,
          config: {
            isOnboardingSynchronization: false,
            showNotification: true,
          },
          request: generateGatewaySyncRequest(
            foundStoreGatewayIntegration.synchronizationName,
            data.storeAliasId,
            params,
          ),
        });
      }
    },
    [addSynchronization, format, subDays, utcToZonedTime],
  );

  const handleStoreSynchronizations = React.useCallback(
    async (integrations: IStoreIntegrations, data: IResyncData) => {
      const foundStoreProviderIntegration = STORE_PROVIDERS.find(integration => {
        const foundActiveIntegration = integrations.store_front.find(
          storeFrontIntegration => storeFrontIntegration.is_active,
        );

        if (!foundActiveIntegration) return false;

        return integration.identifier === foundActiveIntegration.name;
      });

      if (!foundStoreProviderIntegration) return;

      const foundStoreDsersIntegration = integrations.product_cost.find(
        integration => integration.name === EProductCosts.DSERS,
      ) as IIntegration;

      const filteredActiveStoreGatewaysIntegrations = integrations.gateways.filter(
        integration => integration.is_active,
      );

      const foundOtherGateway = integrations.gateways.find(
        gateway => gateway.other_gateway?.has_other_gateway,
      );

      const otherGatewayNames = foundOtherGateway
        ? foundOtherGateway.other_gateway?.gateway_names || []
        : [];

      const hasOtherName = otherGatewayNames.includes('OTHER');
      if (hasOtherName) {
        const otherNameIndex = otherGatewayNames.indexOf('OTHER');

        otherGatewayNames.splice(otherNameIndex, 1);

        otherGatewayNames.push(EGateway.OTHER_GATEWAY);
      }

      const mappedFilteredActiveStoreGatewayIntegrations = filteredActiveStoreGatewaysIntegrations.map(
        integration => integration.name,
      );

      const gateways = [...mappedFilteredActiveStoreGatewayIntegrations, ...otherGatewayNames];

      const filteredStoreGatewaysIntegrations = GATEWAYS.filter(integration =>
        gateways.includes(integration.identifier),
      );

      await addStoreProductsSynchronization(foundStoreProviderIntegration, data);

      if (foundStoreDsersIntegration.is_active) {
        await addDsersProductsSynchronization(data);
      }

      await addStoreProviderSynchronization(foundStoreProviderIntegration, data);

      if (foundStoreDsersIntegration.is_active) {
        await addDsersOrdersSynchronization(data);
      }

      for (const integration of filteredStoreGatewaysIntegrations) {
        addStoreGatewaysSynchronizations(integration, foundStoreProviderIntegration, data);
      }
    },
    [
      addDsersOrdersSynchronization,
      addDsersProductsSynchronization,
      addStoreGatewaysSynchronizations,
      addStoreProviderSynchronization,
      addStoreProductsSynchronization,
    ],
  );

  const resyncStore = React.useCallback(
    async (data: IResyncData) => {
      try {
        const { data: dataStore } = await storesService.getStorePromise({ storeAliasId });

        if (!dataStore) return;

        const { integrations } = dataStore;

        await handleStoreSynchronizations(integrations, data);
      } catch (error: any) {
        console.log(error);
        throw new Error(error);
      }
    },
    [storeAliasId, handleStoreSynchronizations],
  );

  React.useEffect(() => {
    if (!subscription) return;

    if (!user) return;

    if (isInternalTeam || user.role === 'VIP') return;

    if (subscription.status !== EStatus.CANCELED && subscription.status !== EStatus.TRIAL_ENDED)
      return;

    let subscriptionStatusStartedAt: string;

    if (subscription.status === EStatus.CANCELED) {
      subscriptionStatusStartedAt = subscription.canceled_at as string;
    } else {
      subscriptionStatusStartedAt = subscription.updated_at;
    }

    const difference = differenceInDays(
      new Date(subscriptionStatusStartedAt),
      utcToZonedTime(new Date()),
    );

    if (difference > 1) {
      analytics?.trackHubSpot(storeAliasId, { is_returned_after_trial_or_canceled: true });
    }
  }, [
    user,
    subscription,
    differenceInDays,
    utcToZonedTime,
    analytics,
    storeAliasId,
    isInternalTeam,
  ]);

  React.useEffect(() => {
    if (store && !store.domain) {
      setIsDomainModalOpen(true);
    }
  }, [store]);

  React.useEffect(() => {
    if (!currentSynchronizations && !gatewaySynchronization) return;

    if (!currentSynchronizations && gatewaySynchronization) {
      mutateFeedGateways();
      mutateFeedQuantity();
      return;
    }

    const foundGatewaySynchronization = currentSynchronizations.synchronizations.find(
      synchronization => synchronization.type === ESynchronizationType.STORE_GATEWAY,
    );

    if (foundGatewaySynchronization) {
      setGatewaySynchronization(foundGatewaySynchronization);
    }

    if (!foundGatewaySynchronization && gatewaySynchronization) {
      mutateFeedGateways();
      mutateFeedQuantity();
    }

    if (
      foundGatewaySynchronization?.label !== gatewaySynchronization?.label &&
      foundGatewaySynchronization?.storeAliasId === gatewaySynchronization?.storeAliasId
    ) {
      mutateFeedGateways();
      mutateFeedQuantity();
    }
  }, [currentSynchronizations, gatewaySynchronization, mutateFeedGateways, mutateFeedQuantity]);

  React.useEffect(() => {
    if (store) {
      handleTimezone(store.iana_timezone);
      handleSelectedStore(store);
    }
  }, [store, handleTimezone, handleSelectedStore]);

  React.useEffect(() => {
    if (store && store.is_deleted) {
      history.push('/stores');
    }
  }, [store, history]);

  React.useEffect(() => {
    if (feedElementsQuantity) {
      analytics?.track(
        'Remaining Tasks To Solve',
        { remaining_tasks: feedElementsQuantity },
        { context: { groupId: storeAliasId } },
      );
    }
  }, [analytics, feedElementsQuantity, storeAliasId]);

  React.useEffect(() => {
    if (feedElementsQuantity <= countMarkedAsRead) {
      setCountMarkedAsRead(feedElementsQuantity);
      setFeedQuantity(0);
      return;
    }
    if (feedElementsQuantity > countMarkedAsRead) setCountMarkedAsRead(null);

    if (feedElementsQuantity) {
      setFeedQuantity(feedElementsQuantity);
    }
  }, [countMarkedAsRead, feedElementsQuantity, setCountMarkedAsRead]);

  React.useEffect(() => {
    if (gatewaysToFeed) {
      setFeedGateways(gatewaysToFeed);
    }
  }, [gatewaysToFeed]);

  React.useEffect(() => {
    if (resyncData && location.pathname.includes('/dashboard/')) {
      handleResyncData(null);
      resyncStore(resyncData);
    }
  }, [resyncData, location, resyncStore, handleResyncData]);

  React.useEffect(() => {
    if (
      !isLoadingUser &&
      !userError &&
      userAffiliate &&
      (userAffiliate.referral_code === 'njlimzb' || userAffiliate.referral_code === 'zmewzdd') &&
      userTerm.term_to_provide_data_to_mamba_culture_accepted_status === EMambaTermsStatus.NONE
    ) {
      history.push('/consent/mamba-digital');
    }
  }, [isLoadingUser, userError, userAffiliate, history, userTerm]);

  React.useEffect(() => {
    if (
      !isLoadingUser &&
      !userError &&
      userAffiliate &&
      userAffiliate.referral_code === 'nwjkody' &&
      userTerm.term_to_provide_data_to_bequest_culture_accepted_status === EBequestTermsStatus.NONE
    ) {
      history.push('/consent/bequest-digital');
    }
  }, [isLoadingUser, userError, userAffiliate, history, userTerm]);

  React.useEffect(() => {
    if (
      !isLoadingPersonalInfo &&
      !personalInfoError &&
      !personalInfo &&
      !personalInfoConfig &&
      !user.has_answered_form_segmentation
    ) {
      history.push('/onboarding/segmentation');
    }

    if (personalInfo) {
      handlePersonalInfo(personalInfo);
    }
  }, [
    isLoadingPersonalInfo,
    personalInfoError,
    personalInfo,
    history,
    handlePersonalInfo,
    personalInfoConfig,
    user,
  ]);

  // React.useEffect(() => {
  //   if (store?.type && store.type === EBusinessType.TRADITIONAL_ECOMMERCE) {
  //     refiner('showForm', '5d82ec10-afd9-11ee-918b-9dfa929daeaf');
  //   }
  // }, [store]);

  React.useEffect(() => {
    if (stores) {
      handleStores(stores);
    }
  }, [stores, handleStores]);

  React.useEffect(() => {
    if (store && subscription && user && storeIntegrations) {
      const foundStoreFront = storeIntegrations.store_front.find(
        integration => integration.is_active,
      );
      const filteredGateways = storeIntegrations.gateways.filter(
        integration => integration.is_active,
      );
      const filteredMarketings = storeIntegrations.marketing.filter(
        integration => integration.is_active,
      );

      const mappedFilteredGateways = filteredGateways.map(filteredGateway => filteredGateway.name);
      const mappedFilteredMarketing = filteredMarketings.map(
        filteredMarketing => filteredMarketing.name,
      );

      analytics?.group(store.alias_id, {
        store_alias_id: store.alias_id,
        created_at: store.created_at,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        store_name: store.name,
        type: store.type,
        ecom_platform: foundStoreFront?.name,
        gateways: mappedFilteredGateways,
        marketing_channel: mappedFilteredMarketing,
        is_onboarding_finished: store.is_onboarding_finished,
        is_active: store.is_active,
        plan_status: subscription.status,
        plan_name: subscription.plan?.name,
        plan_id: subscription.plan?.id,
        plan_price: subscription.plan?.amount,
      });
      analytics?.trackHubSpot(store.alias_id, {
        created_at: store.created_at,
        is_active: store.is_active,
      });
    }
  }, [store, subscription, user, storeIntegrations, analytics]);

  React.useEffect(() => {
    if (hasStartedOpenReplay && store) {
      openReplayTracker.setMetadata('store_type', store.type);
    }
  }, [hasStartedOpenReplay, openReplayTracker, store]);

  React.useEffect(() => {
    if (userData) {
      handleUser({ ...userData, user_intercom_hash: userIntercomHash });
    }
  }, [userData, handleUser, userIntercomHash]);

  React.useEffect(() => {
    if (userAffiliate) {
      handleAppAffiliate(userAffiliate);
    }
  }, [userAffiliate, handleAppAffiliate]);

  React.useEffect(() => {
    if (userTerm) {
      handleTerm(userTerm);
    }
  }, [userTerm, handleTerm]);

  React.useEffect(() => {
    if (!mobileNotification && !isLoadingMobileNotification) {
      mobileNotificationService.createMobileNotification({
        storeAliasId,
        data: {
          send_ad_sense_account_insight_notification: true,
          send_blocked_facebook_ad_sense_accounts_notification: true,
          send_compensated_boleto_notification: true,
          send_five_hour_net_profit_notification: true,
          send_goal_notification: true,
          send_last_daily_net_profit_notification: true,
          send_net_profit_notification: true,
          send_automation_first_time_recover_notification: true,
          send_automation_recover_notification: true,
        },
      });
    }
  }, [mobileNotification, isLoadingMobileNotification, storeAliasId]);

  React.useEffect(() => {
    if (!storeIntegrations && !store) return;

    const { store_front, gateways, marketing } = storeIntegrations;

    const foundStoreFrontIntegration = store_front.find(integration => integration.is_active);

    if (foundStoreFrontIntegration) {
      analytics?.track(
        'Store Integration',
        {
          ecommerce_provider: foundStoreFrontIntegration.name,
          email: user?.email,
        },
        { context: { groupId: storeAliasId } },
      );
    }

    const filteredMarketingIntegrations = marketing.filter(integration => integration.is_active);

    if (filteredMarketingIntegrations.length > 0) {
      const mappedIntegrations = filteredMarketingIntegrations.map(integration => integration.name);

      analytics?.track(
        'Store Marketing Integrations',
        {
          selected_ads_providers: mappedIntegrations,
          email: user?.email,
        },
        { context: { groupId: storeAliasId } },
      );
    }

    const filteredGatewayIntegrations = gateways.filter(integration => integration.is_active);

    if (filteredGatewayIntegrations.length > 0) {
      const mappedIntegrations = filteredGatewayIntegrations.map(integration => integration.name);

      analytics?.track(
        'Store Gateways Integrations',
        {
          selected_gateways: mappedIntegrations,
          email: user?.email,
        },
        { context: { groupId: storeAliasId } },
      );
    }
  }, [analytics, store, storeAliasId, storeIntegrations, user]);

  React.useEffect(() => {
    setIsLoadingInitialCheckoutFee(isLoadingDefaultCheckoutFee || isValidatingDefaultCheckoutFee);
    setIsLoadingInitialStore(isLoadingStore || isValidatingStore);
  }, [
    isLoadingDefaultCheckoutFee,
    isLoadingStore,
    isValidatingDefaultCheckoutFee,
    isValidatingStore,
  ]);

  React.useEffect(() => {
    handleCurrentStore(store);
    handleValidatingCurrentStore(isValidatingStore);
  }, [store, isValidatingStore, handleCurrentStore, handleValidatingCurrentStore]);

  React.useEffect(() => {
    if (processingData === 'true' || noRetroActiveData === 'true') return;

    if (isLoadingInitialStore || isLoadingInitialCheckoutFee) return;

    if (!store) return;

    if (store.is_onboarding_finished) return;

    const activeIntegration = storeIntegrations.store_front.find(
      integration => integration.is_active,
    );

    if (store.type === EBusinessType.INFO_PRODUCT && !activeIntegration) {
      history.push(`/onboarding/${storeAliasId}/info-product/integrations`);
      return;
    }

    if (store.type === EBusinessType.TRADITIONAL_ECOMMERCE && !activeIntegration) {
      history.push(`/onboarding/${storeAliasId}/ecommerce/integrations`);
      return;
    }

    if (store.type === EBusinessType.DROPSHIPPING && !activeIntegration) {
      history.push(`/onboarding/${storeAliasId}/dropshipping/integrations`);
      return;
    }

    if (
      store.type === EBusinessType.DROPSHIPPING &&
      !defaultCheckoutFee &&
      !defaultCheckoutFeeError
    ) {
      history.push(`/onboarding/${storeAliasId}/dropshipping/checkout-fee`);
    }
  }, [
    defaultCheckoutFee,
    history,
    store,
    storeAliasId,
    storeIntegrations,
    isLoadingInitialCheckoutFee,
    isLoadingInitialStore,
    noRetroActiveData,
    processingData,
    defaultCheckoutFeeError,
  ]);

  const isLoadingStoreConfig =
    isLoadingSubscriptionProviderData ||
    isLoadingStore ||
    isLoadingFeedQuantity ||
    isLoadingFeedGateways ||
    isLoadingStores ||
    isLoadingUser ||
    isLoadingUserGroup ||
    isLoadingDefaultCheckoutFee;
  const isValidatingStoreConfig =
    isValidatingSubscriptionProviderData ||
    isValidatingStore ||
    isValidatingStores ||
    isValidatingUser ||
    isValidatingUserGroup ||
    isValidatingDefaultCheckoutFee;

  const isStoreConfigError =
    Boolean(isSubscriptionProviderDataError) ||
    Boolean(storeError) ||
    Boolean(storesError) ||
    Boolean(userError) ||
    Boolean(userGroupError);

  return (
    <StoreConfigContext.Provider
      value={{
        isLoadingStoreConfig,
        isValidatingStoreConfig,
        mutateStoreConfig,
        isStoreConfigError,
        isValidatingStore,
        isLoadingStore,
        storeIntegrations,
        feedQuantity,
        mutateFeedQuantity,
        mutateStore,
        feedGateways,
        mutateFeedGateways,
        handleDomainModalOpen,
        isDomainModalOpen,
        store,
        stores,
        userData,
        accessManagementData,
        handleMarkAsReadFeed,
        isDeleteSyncsModalOpen,
        handleDeleteSyncsModalOpen,
        showDsersInactiveAlert,
        toggleDsersInactiveModal,
      }}
    >
      {children}
    </StoreConfigContext.Provider>
  );
};

export const useStoreConfig = (): IStoreConfigProvider => {
  const context = React.useContext(StoreConfigContext);

  if (!context) {
    throw new Error('useStoreConfig must be used within StoreConfigProvider');
  }

  return context;
};
