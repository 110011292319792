import React from 'react';
import { Dsers } from '@profitfy/pakkun-icons';
import { X } from 'phosphor-react';
import { useTheme } from 'styled-components';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import * as S from './styles';

interface ISuccessfulMessage {
  toggle: () => void;
}

const SuccessulMessage: React.FC<ISuccessfulMessage> = ({ toggle }) => {
  const { colors } = useTheme();
  const { toggleDsersInactiveModal } = useStoreConfig();

  const handleSuccess = React.useCallback(() => {
    toggleDsersInactiveModal();
    toggle();
  }, [toggleDsersInactiveModal, toggle]);

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <S.TitleContainer>
          <Dsers size={32} />
          <S.Title>DSers reativado com sucesso!</S.Title>
        </S.TitleContainer>

        <S.CloseButtonWrapper onClick={handleSuccess}>
          <X size={20} color={colors.gray[1]} />
        </S.CloseButtonWrapper>
      </S.HeaderWrapper>
      <S.Description>
        Sincronização reestabelecida com sucesso! Você já pode retomar o uso normal do DSers.
      </S.Description>
    </S.Wrapper>
  );
};

export default SuccessulMessage;
