import { API_DOMAIN } from '@constants/api';
import {
  ICreateDsersCredentialProps,
  IDeleteDsersCredentialProps,
  IGetDsersCredentialProps,
  IGetDsersCredentialResponse,
  IUpdateDsersCredentialProps,
} from '@domain/interfaces/dashboard/IntegrationCenter/Erp/Bling/IBlingAuth';
import { useFetch } from '@providers/Fetch/useFetch';
import { ApiService } from '@services/api';

export class DsersAuthService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public getDsersCredential({
    storeAliasId,
  }: IGetDsersCredentialProps): IGetDsersCredentialResponse {
    const { data, error, mutate, isValidating } = useFetch(
      `/api/v1/users/stores/${storeAliasId}/dsers-credentials`,
      this.apiService,
    );

    const isLoading = !error && !data;

    return {
      dsersCredential: data?.dsers_credential,
      error,
      isLoading,
      isValidating,
      mutate,
    };
  }

  public createDsersCredential({ storeAliasId, data }: ICreateDsersCredentialProps): Promise<any> {
    return this.apiService.post(`/api/v1/users/stores/${storeAliasId}/dsers-credentials`, {
      ...data,
    });
  }

  public updateDsersCredential({ storeAliasId, data }: IUpdateDsersCredentialProps): Promise<any> {
    return this.apiService.put(`/api/v1/users/stores/${storeAliasId}/dsers-credentials`, {
      ...data,
    });
  }

  public deleteDsersCredential({ storeAliasId }: IDeleteDsersCredentialProps): Promise<any> {
    return this.apiService.delete(`/api/v1/users/stores/${storeAliasId}/dsers-credentials`);
  }

  public getDsersCredentialPromise({ storeAliasId }: IGetDsersCredentialProps): Promise<any> {
    return this.apiService.get(`/api/v1/users/stores/${storeAliasId}/dsers-credentials`);
  }
}

const dsersAuthService = new DsersAuthService(API_DOMAIN);

export default dsersAuthService;
