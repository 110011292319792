import styled from 'styled-components';

import TextBase from '@components/common/core/DataDisplay/Text';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Title = styled(TextBase)`
  font-weight: 500;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const CloseButtonWrapper = styled.div`
  cursor: pointer;
`;

export const Description = styled(TextBase)`
  font-weight: 400;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray[1]};
`;

export const Input = styled(TextFieldBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export const CancelButton = styled.button`
  border: 0;
  border-radius: 5px;
  background-color: #2e323e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Mukta';
  font-weight: 700;
  font-size: 0.875rem;
  height: 42px;
  padding: 12px 22px;
  color: ${({ theme }) => theme.colors.white.default};
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
  }
`;

export const ReSyncButton = styled.button`
  border: 0;
  border-radius: 5px;
  background-color: #00f2a1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Mukta';
  font-weight: 700;
  font-size: 0.875rem;
  height: 42px;
  padding: 12px 22px;
  color: ${({ theme }) => theme.colors.black.default};
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
  }
`;
