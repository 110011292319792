import { ArrowSquareOut } from 'phosphor-react';
import React from 'react';
import { useTheme } from 'styled-components/macro';

import { ETabOption } from '@domain/enums/dashboard/storeSubscription/ETabOptions';

import { useStoreSubscription as useStoreSubscriptionPage } from '@helpers/hooks/pages/dashboard/storeSubscription/useStoreSubscription';
import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { format } from 'date-fns';

import CancelSubscription from '@components/Dashboard/StoreSubscription/Subscription/Modals/CancelSubscription';

import * as S from './styles';

const Free: React.FC = () => {
  const theme = useTheme();
  const { handleSelectedTab } = useStoreSubscriptionPage();
  const { subscription } = useStoreSubscription();

  const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] = React.useState<boolean>(
    false,
  );

  const toggle = React.useCallback(() => setIsCancelSubscriptionModalOpen(state => !state), []);

  const onUpgradeClick = React.useCallback(() => {
    handleSelectedTab(ETabOption.PLANS);
  }, [handleSelectedTab]);

  const currentConsumption = subscription.current_orders_quantity;
  const maxConsumption = subscription.allowed_orders_quantity;
  const periodStarted = subscription.period_started_at;
  const periodEnded = subscription.period_ended_at;

  const consumption =
    (currentConsumption * 100) / maxConsumption > 100
      ? 100
      : (currentConsumption * 100) / maxConsumption;

  return (
    <>
      <S.CardWrapper>
        <S.Content>
          <S.PlanNameAndPricingWrapper>
            <S.TitleHeader>
              <S.PlanName>Lite</S.PlanName>
              <S.PeriodWrapper>
                <S.StatusText>{`De ${format(new Date(periodStarted), 'dd/MM/yyyy')}`}</S.StatusText>
                <S.StatusText>{`Até ${format(new Date(periodEnded), 'dd/MM/yyyy')}`}</S.StatusText>
              </S.PeriodWrapper>
            </S.TitleHeader>
            <S.PricingValue>Grátis</S.PricingValue>
          </S.PlanNameAndPricingWrapper>

          <S.Divider />

          <S.OrderConsumptionWrapper>
            <S.Description>Consumo de pedidos</S.Description>

            <S.Consumption>
              <S.TotalConsumption>
                <S.CurrentConsumption style={{ width: `${consumption}%` }} />
              </S.TotalConsumption>

              <S.ConsumptionValuesWrapper>
                <S.CurrentConsumptionText>{currentConsumption}</S.CurrentConsumptionText>
                <S.MaxConsumptionText>{maxConsumption}</S.MaxConsumptionText>
              </S.ConsumptionValuesWrapper>
            </S.Consumption>
          </S.OrderConsumptionWrapper>
        </S.Content>

        <S.OptionsWrapper>
          <S.CancelationButton onClick={toggle}>Cancelar</S.CancelationButton>

          <S.UpgradeButton onClick={onUpgradeClick}>
            Upgrade <ArrowSquareOut size={18} color={theme.colors.gray[1]} />
          </S.UpgradeButton>
        </S.OptionsWrapper>
      </S.CardWrapper>
      <CancelSubscription isOpen={isCancelSubscriptionModalOpen} toggle={toggle} />
    </>
  );
};

export default Free;
