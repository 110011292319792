import styled from 'styled-components';

import TextBase from '@components/common/core/DataDisplay/Text';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Title = styled(TextBase)`
  font-weight: 500;
  font-size: 1.25rem;
  color: #00f2a1;
`;

export const CloseButtonWrapper = styled.div`
  cursor: pointer;
`;

export const Description = styled(TextBase)`
  font-weight: 400;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray[1]};
`;
