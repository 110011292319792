import React from 'react';
import { Dsers } from '@profitfy/pakkun-icons';

import DsersCredentialModal from './DserCredentialModal';

import * as S from './styles';

interface IDsersInactiveProps {
  toggle: () => void;
}

const DsersInactive: React.FC<IDsersInactiveProps> = ({ toggle }) => {
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const toggleModal = React.useCallback(() => {
    setShowModal(state => !state);
  }, []);

  return (
    <S.Container>
      <S.Content>
        <S.InfoContent>
          <Dsers size={40} />
          <S.TextContent>
            <S.Title>Credencial DSers inativada!</S.Title>
            <S.SubTitle>
              Para retomar o uso do DSers e reiniciar a sincronização, por favor, reinsira suas
              credenciais.
            </S.SubTitle>
          </S.TextContent>
        </S.InfoContent>
        <S.ActionsWrapper>
          <S.ReSyncButton onClick={toggleModal}>Reativar sincronização</S.ReSyncButton>
          <S.CloseButton onClick={toggle}>Fechar</S.CloseButton>
        </S.ActionsWrapper>
      </S.Content>
      <DsersCredentialModal isOpen={showModal} toggle={toggleModal} />
    </S.Container>
  );
};

export default DsersInactive;
