import React from 'react';

import CredentialForm from './CredentialForm';
import SuccessfulMessage from './SuccessfulMessage';

import * as S from './styles';

interface IDsersCredentialProps {
  isOpen: boolean;
  toggle: () => void;
}

const DsersCredentialModal: React.FC<IDsersCredentialProps> = ({ isOpen, toggle }) => {
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);

  const handleSuccess = React.useCallback(() => {
    setIsSuccess(true);
  }, []);

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      {!isSuccess ? (
        <CredentialForm toggle={toggle} handleSuccess={handleSuccess} />
      ) : (
        <SuccessfulMessage toggle={toggle} />
      )}
    </S.Modal>
  );
};

export default DsersCredentialModal;
