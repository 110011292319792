import React from 'react';
import { Dsers } from '@profitfy/pakkun-icons';
import { X } from 'phosphor-react';
import { useTheme } from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import dsersAuthService from '@services/pages/dashboard/integrationCenter/erp/dsers/dsersAuth';

import { useToast } from '@helpers/hooks/useToast';
import { dsersCredentialSchema } from '@helpers/validators/dashboard/integrationCenter/erp/dsers';
import { IParams } from '@domain/interfaces/IParams';

import Text from '@components/common/core/DataDisplay/Text';
import Form from '@components/common/core/Inputs/Form';

import * as S from './styles';

interface ICredentialForm {
  toggle: () => void;
  handleSuccess: () => void;
}

const CredentialForm: React.FC<ICredentialForm> = ({ toggle, handleSuccess }) => {
  const { colors } = useTheme();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ mode: 'onBlur', resolver: yupResolver(dsersCredentialSchema) });

  const [isUpdatingCredential, setIsUpdatingCredential] = React.useState<boolean>(false);

  const { dsersCredential } = dsersAuthService.getDsersCredential({ storeAliasId });

  const onFormSubmit = React.useCallback(
    async data => {
      setIsUpdatingCredential(true);

      try {
        await dsersAuthService.updateDsersCredential({
          storeAliasId,
          data: {
            email: data.email,
            password: data.password,
            sync_order_cogs: dsersCredential?.sync_order_cogs,
            sync_order_shipping_cost_owner: dsersCredential?.sync_order_shipping_cost_owner,
            sync_products: dsersCredential?.sync_products,
            sync_products_in_sync_order_cogs: dsersCredential?.sync_products_in_sync_order_cogs,
            sync_remessa_conforme: dsersCredential?.sync_remessa_conforme,
          },
        });

        handleSuccess();
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }

      setIsUpdatingCredential(false);
    },
    [handleSuccess, storeAliasId, toast, dsersCredential],
  );

  React.useEffect(() => {
    if (dsersCredential) {
      setValue('email', dsersCredential.email);
    }
  }, [dsersCredential, setValue]);

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <S.TitleContainer>
          <Dsers size={32} />
          <S.Title>Reativar DSers</S.Title>
        </S.TitleContainer>

        <S.CloseButtonWrapper onClick={toggle}>
          <X size={20} color={colors.gray[1]} />
        </S.CloseButtonWrapper>
      </S.HeaderWrapper>
      <S.Description>
        Sua sincronização com a DSers foi inativada. Insira abaixo seu e-mail e senha para reativar
        a sincronização e voltar a utilizar normalmente.
      </S.Description>

      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <S.InputGroup>
          <S.Label htmlFor="email">E-mail</S.Label>

          <S.Input
            {...register('email')}
            type="text"
            id="email"
            placeholder="Digite o e-mail do Dsers"
          />
          {errors.email && <Text isErrorFeedback>{errors.email.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label htmlFor="password">Senha</S.Label>

          <S.Input
            {...register('password')}
            type="password"
            id="password"
            placeholder="Digite a senha do Dsers"
          />
          {errors.password && <Text isErrorFeedback>{errors.password.message}</Text>}
        </S.InputGroup>

        <S.ButtonsWrapper>
          <S.CancelButton type="button" onClick={toggle}>
            Cancelar
          </S.CancelButton>

          <S.ReSyncButton type="submit" disabled={isUpdatingCredential}>
            Reativar sincronização
          </S.ReSyncButton>
        </S.ButtonsWrapper>
      </Form>
    </S.Wrapper>
  );
};

export default CredentialForm;
